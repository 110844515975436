.logs {
  .header-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .ant-tabs-ink-bar {
    background: @primary-color;
  }

  .ant-tabs-tabpane {
    @media screen and (max-width: @screen-lg-max) {
      height: max-content;
      padding: 12px;
      background: white;
    }
    .ant-descriptions-item-label {
      color: @grey-10;
    }
    .ant-descriptions-item-content {
      color: @header-text-color;
    }
    .details-owner-description {
      margin-bottom: 25px;
    }
  }
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    margin-bottom: 0px;
  }
  .ant-tabs-tab {
    width: 140px;
    display: flex;
    border-radius: 8px 8px 0px 0px;
    justify-content: center;
    background: #e5e8ed;
    &:nth-child(2) {
      width: 180px;
    }

    &-active {
      background: @white-color;
      color: @primary-color;
      .ant-tabs-tab-btn {
        color: @primary-color;
      }
    }
    &:hover {
      color: @primary-color;
    }
    .ant-tabs-top > .ant-tabs-nav,
    .ant-tabs-bottom > .ant-tabs-nav,
    .ant-tabs-top > div > .ant-tabs-nav,
    .ant-tabs-bottom > div > .ant-tabs-nav {
      margin-bottom: 0px;
    }
  }

  & .ant-tabs-tabpane {
    background: @white-color;
    border-radius: 0px 8px 8px 8px;
  }
}

.ant-table-thead > tr > th {
  background: @white-color;
}

.ant-table-content {
  border: 1px solid #e5e8ed;
  border-radius: 8px;
}

.instruction-logs-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.instruction-selector {
  .ant-select-selector {
    display: flex;
    align-items: center;
  }
}

.activity-scroll-wrapper,
.instruction-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 200px);
  min-height: 400px;
}
