.approval-link {
  @media screen and(max-width:999px) {
    padding: 16px;
  }
  .bypass-card {
    margin-top: 0;
  }
  .eqc-summary-card {
    background: transparent;
    width: 100%;
    border: none;
    .ant-card-body {
      padding: 0;
    }
  }
  background-color: @light-blue;
  .ant-table-content {
    border: 0px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }

  tbody.ant-table-tbody {
    color: @header-text-color;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 5px 5px;
  }
  .ant-btn {
    margin-right: 5px;
    display: flex;
    align-items: center;
    svg {
      width: 25px;
      fill: @white-color;
      @media screen and(min-width:999px) {
        margin-right: 5px;
      }
    }
  }

  .ant-descriptions {
    max-width: 70%;
    width: 100%;
  }

  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding-bottom: 4px;
  }

  .general-comment {
    background: @header-text-color;
    border: none;
    color: @white-color;
    &:focus,
    &:hover {
      background: #202a3fd7 !important; //to overwrite primary buttons specificity
    }
    &[disabled] {
      color: @white-color;
      background: rgba(166, 170, 178);
    }
  }

  .ant-image-mask:hover {
    opacity: 0;
  }
  .image-wrapper {
    & > div:nth-child(n + 3) {
      display: none;
    }
  }
  .common-item {
    .ant-descriptions-item-content {
      margin-bottom: 20px;
    }
  }
  .ant-descriptions-item-container {
    margin-right: 20px;
  }
  .ant-descriptions-item-container .ant-descriptions-item-label,
  .ant-descriptions-item-container .ant-descriptions-item-content {
    align-items: center;
  }

  .ant-descriptions-item-label {
    color: @grey-10;
  }
  .ant-descriptions-item-content {
    color: @header-text-color;
  }
  .ant-descriptions-item-content {
    display: block;
  }
  .previous-comment {
    background: @light-blue;
    border-color: @light-blue;
    color: @grey-10;
    margin-top: 10px;
  }
  .eqc-general-details {
    .main-approver-divider {
      border-top: 2px solid rgba(0, 0, 0, 0.06);
    }
    .approver-divider {
      width: 60px;
      min-width: 5px;
      margin: 40px 10px;
      @media screen and(max-width:999px) {
        width: 100%;
        margin: 12px 0;
      }
    }
  }
}

.comment-card {
  border: 2px solid @inactive-tag-background;
  border-radius: 16px;
  margin-top: 16px;
  h4 {
    color: @grey-10;
    margin: 0;
  }
  .additional-title {
    color: @text-color;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .ant-card-body {
    padding: 16px;
  }
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .ant-image {
    .ant-image-img {
      object-fit: contain;
    }
  }
  .comment-time {
    font-size: 12px;
    font-weight: 400;
    color: @label-color;
    margin-bottom: 8px;
    line-height: 20px;
  }
  .comment {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    line-height: 24px;
  }
}

.approver-stage-details {
  &-header {
    svg {
      fill: @primary-color;
    }
  }
  .mt-0 {
    margin-top: 0px;
  }
  .additional-text {
    color: @primary-color;
    font-size: 20px;
    font-weight: 400;
    margin: 16px 0px;
    line-height: 28px;
    cursor: pointer;
  }
  .action-button {
    span {
      margin: 0px;
    }
  }
  .checklist-item-text {
    color: @header-text-color;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
  }
}

.success-container {
  background-image: url(~assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &-content {
    padding: 32px 32px;
    font-size: 14px;
    position: relative;
    z-index: 5;
    h1 {
      font-size: 46px;
      margin-bottom: 80px;
    }
    svg {
      margin-bottom: 80px;
    }
  }
}

.success-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}
.approvers-card {
  border: 1px solid @inactive-tag-background;
  border-radius: 4px;
  height: fit-content;
  .ant-card-body {
    padding: 12px;
    width: 280px;
    color: @label-color;
  }
  .ant-tag.approver-name-tag {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }
  .level-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
  @media screen and(max-width:999px) {
    .ant-card-body {
      padding: 0;
      width: 100%;
      min-width: unset;
    }
    border: none;
  }
}

.comment-popup {
  .ant-divider-horizontal {
    margin: 16px 0;
  }
  .add-comment-button {
    border-radius: 8px;
    border: 1px solid @border-color;
    padding: 16px;
  }
  .level-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: @grey-8;
  }
  .ant-popover-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: @text-color;
    padding: 12px;
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-content {
    width: 400px;
  }
  .ant-popover-inner {
    border-radius: 12px;
  }
  .ant-popover-inner-content {
    padding: 16px;
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
  .comment-data-card {
    border: 1px solid @inactive-tag-background;
    border-radius: 8px;
    padding: 16px;
    .name-and-time {
      font-size: 12px;
      line-height: 20px;
    }
    .comment {
      font-size: 16px;
      color: @label-color;
      font-weight: 400;
      line-height: 24px;
    }
  }
  @media screen and(max-width:500px) {
    padding: 0 5px;
    .ant-popover-content {
      width: 310px;
    }
  }
}
.approval-confirm-modal {
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .title {
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    color: @text-color;
  }
  .description {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: @grey-10;
  }
  .ant-btn-round {
    font-weight: 500;
    padding: 7.9px 40px;
  }
  .ant-modal-content {
    border-radius: 16px;
    width: 350px;
  }
}

.approval-selection-modal {
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
}

.general-comment {
  background: @header-text-color;
  border: none;
  color: @white-color;
  &:focus,
  &:hover {
    background: #202a3fd7 !important; //to overwrite primary buttons specificity
  }
  &[disabled] {
    color: @white-color;
    background: rgba(166, 170, 178);
  }
}
